<template>
  <div class="addLogo">
    <div class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{$t('addLogoHeader')}}</h1>
    </div>
    <form id="app" @submit.prevent="handleSubmit">
      <div class="container-fluid pt-5 pr-5 pl-5">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-4 col-lg-12 col-xs-12 container-mobile-content">
            <div class="form-group">
              <label for="name">{{$t('logoBrandNameLabel')}} <span>*</span></label>
              <input type="text" v-model="name" class="form-control" name="name" id="name" :placeholder="$t('logoBrandNameLabel')" :class="{ 'is-invalid': submitted && $v.name.$error }">
              <div v-if="submitted && !$v.name.required" class="invalid-feedback">{{$t('fillFieldError')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid container-mobile-content">
        <div class="row col-12 pt-3 pl-5 d-flex justify-content-center align-items-center">
          <div class="form-group">
            <small class="form-text text-muted pl-4 pb-1">{{$t('logoImageSizeInfo')}}</small>
            <input type="file" class="input-file" @change="onFileSelected" name="image" id="image">
            <p class="file-label">{{$t('insertImage')}} <i class="fas fa-image ml-2"></i></p>
          </div>
          <div class="pb-2">
            <img class="round" width="130px" height="auto" :src="''">
          </div>
        </div>
      </div>
      <div class="container-fluid pr-5 pl-5">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-2 pt-1 d-flex justify-content-center">
            <div class="form-group">
              <button type="submit" class="btn btn-primary pr-3">{{$t('send')}} <i class="fas fa-pencil-alt ml-2"></i></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- <Setting :location="'logo/add'" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import { required } from "vuelidate/lib/validators";
import Setting from '@/components/Settings.vue';

export default {
  data() {
    return {
      name: null,
      imageBase64: null,
      submitted: false,
      url: utils.getConfig().URL,
    }
  },
  components:{
    Setting,
  },
  validations: {
    name: {required}
  },
  methods: {
    onFileSelected(event){
      let result;
      this.image = event.target.files[0]

      var reader = new FileReader();
      reader.readAsDataURL(this.image);
      reader.onload =  (e) => {
        result = e.target.result;
        $(".round").attr("src", result);
        $(".round").css("margin-left", "1rem");
        this.imageBase64 = result.split(',')[1];
      };
    },
    handleSubmit(e){
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      else{
        var dataToInsert = { 
          brandname : this.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("'", "").replace(/ /g,"_"),
          imageBase64 : this.imageBase64,
        }

        const formData = new FormData();
        formData.append('params', JSON.stringify(dataToInsert));

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.$http.post(this.url + 'image/logo/save', formData)
        .then(function(response){
          loader.hide();
          window.location = "/success";
        })
      }
    },
  },
}
</script>


